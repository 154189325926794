@import 'src/styles/colors';

.wallet-connect {
    width: 280px;
    top: 70px;
    right: 80px;
    padding: 24px 0 0 0;
    position: fixed;
    background: white;
    border: 1px solid rgba(147, 170, 200, 0.2);
    box-sizing: border-box;
    box-shadow: 12px 12px 70px rgba(17, 51, 102, 0.1);
    border-radius: 12px;

    .title {
        padding: 0 24px 18px 24px;
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        letter-spacing: -0.015em;

        img {
            cursor: pointer;
        }
    }

    .item-wrapper {
        cursor: pointer;
    }

    .item {
        cursor: pointer;
        color: $dark-blue;
        display: flex;
        align-items: center;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        margin-left: 24px;
        padding-top: 18px;
        padding-bottom: 18px;

        img {
            margin-right: 16px;
        }

        div {
            display: flex;
            align-items: center;
        }

        a {
            color: inherit;
            text-decoration: none;
        }
    }

    .item:not(.disconnect) {
        justify-content: space-between;
    }

    .item.selected {
        color: #00ba77;
    }

    .item-wrapper:hover {
        background: rgba(0, 106, 237, 0.12);
    }

    :not(:last-child).item-wrapper > .item {
        border-bottom: rgba(147, 170, 200, 0.5) solid 1px;
    }

    .disconnect {
        color: $error;
        border-radius: 0 0 12px 12px;
        margin-left: 0;

        img {
            margin-left: 24px;
        }
    }

    .disconnect:hover {
        background: rgba(253, 104, 104, 0.1);
    }

    .inactive {
        opacity: 0.5;
    }
}

.hide {
    display: none;
}

@media only screen and (max-width: 640px) {
    .wallet-connect {
        right: 32px;
    }
}
