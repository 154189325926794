@import 'src/styles/colors';

.channel-info {
    > div {
        display: flex;
    }

    .rating {
        margin-top: 8px;
        display: flex;
        margin-left: 80px;
        align-items: flex-start;
        flex-direction: column;
        font-size: 18px;
        line-height: 30px;
        letter-spacing: -0.015em;
        color: $rock-blue;

        > div {
            display: flex;
            align-items: center;
            margin-right: 30px;
        }

        img {
            margin-left: 6px;
        }
    }
}

.channel {
    background: white;
    flex: 1 0 auto;
    margin-top: 100px;

    .publish {
        margin-top: 24px;
        .button {
            width: 265px;
            height: 52px;
        }
    }

    .content {
        padding: 40px;
        display: flex;
        font-size: 24px;
        line-height: 32px;

        .block {
            padding: 0 40px;

            &:first-child {
                border-right: $rock-blue 2px solid;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }
    }

    .name {
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        display: flex;

        img {
            margin-left: 6px;
        }
    }

    .buttons {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        white-space: nowrap;

        .copied {
            color: $success;
        }
    }

    .rating {
        margin-top: 8px;
        display: flex;
        align-items: center;
        font-size: 18px;
        line-height: 30px;
        letter-spacing: -0.015em;
        color: $rock-blue;

        > div {
            display: flex;
            align-items: center;
            margin-right: 30px;
        }

        img {
            margin-left: 6px;
        }
    }

    .type-wrapper {
        display: flex;
        align-items: center;
        margin-top: 15px;
    }
    .type {
        color: white;
        background: $rock-blue;
        border-radius: 12px;
        padding: 4px 10px;
        width: fit-content;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
    }

    .shortname {
        color: $light-blue;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        margin-left: 21px;
    }

    .description {
        margin-top: 18px;
        word-wrap: break-word;
    }

    .language {
        margin-top: 6px;
        span {
            font-weight: bold;
        }
    }

    .tags {
        display: flex;
        align-items: center;
        margin-top: 6px;
        cursor: pointer;
        flex-wrap: wrap;
        gap: 5px;

        span {
            font-weight: bold;
        }
        .tag {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: $rock-blue;
            padding: 4px 10px;
            border-radius: 4px;
            border: 2px solid $rock-blue;
            margin-left: 8px;
        }
    }

    .image {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    .image-holder {
        border: 1px solid #c4d2e3;
        border-radius: 50%;
        width: 150px;
        height: 150px;
        background: #c4d2e3;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;

        color: #ffffff;
    }

    .title {
        padding-left: 80px;
        font-weight: 500;
        font-size: 40px;
        line-height: 60px;
        color: $dark-blue;
    }
}

@media only screen and (max-width: 640px) {
    .channel-info {
        flex-direction: column;

        .rating {
            flex-direction: row;
            margin-left: 0;
        }
    }

    .channel {
        .title {
            padding-left: 25px;
        }

        .content {
            flex-direction: column;
            align-items: flex-start;
            padding: 25px;

            .block {
                width: 100%;
                padding: 0;

                .name {
                    margin-top: 25px;
                }
            }

            .block:first-child {
                border: none;
                align-items: flex-start;
            }
        }

        .buttons {
            width: 100%;

            .button {
                width: 100%;
                height: 52px;
            }
        }
    }
}
