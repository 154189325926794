@import 'src/styles/colors';

.service-wrapper {
    padding-top: 70px;
    background: $bg-alternative;
    flex: 1 0 auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    &.catalog {
        justify-content: flex-start;
    }

    .toast-wrapper {
        margin-top: 10px;
    }

    .field {
        margin-top: 16px;
        background: white;
        padding: 40px 50px 50px 50px;
        width: 100%;
        border: 1px solid rgba(147, 170, 200, 0.2);
        box-sizing: border-box;
        border-radius: 10px;
        display: flex;
        align-items: center;
        flex-direction: column;

        .title {
            font-weight: bold;
            font-size: 32px;
            line-height: 60px;
            color: $dark-blue;
        }
    }

    .field-wrapper {
        width: 732px;
    }
}

@media only screen and (max-width: 960px) {
    .service-wrapper {
        .field-wrapper {
            width: 674px;
        }
    }
}

@media only screen and (max-width: 640px) {
    .service-wrapper {
        padding-top: 85px;

        .field-wrapper {
            width: 339px;
        }

        .field {
            padding: 40px 24px 50px 24px;

            .title {
                font-size: 28px;
                line-height: 60px;
            }
        }
    }
}
