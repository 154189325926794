@import 'src/styles/colors';

.collaborations-list {
    max-width: 700px;
    margin-left: 133px;
    margin-right: 133px;
    margin-bottom: 50px;
    width: 100%;
    align-self: center;

    .title {
        margin-top: 120px;
        margin-bottom: 30px;
        font-size: 40px;
        line-height: 60px;
        color: $dark-blue;
        text-align: center;
    }
    .collab {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: #c4d2e3 1px solid;
        padding: 12px 0;

        .logo {
            height: 64px;
            cursor: pointer;
        }

        .links {
            display: flex;
            align-items: center;
            > * {
                height: 32px;
                width: 32px;
                cursor: pointer;
                margin-left: 16px;

                &:hover path {
                    fill: $hover-primary;
                }
            }
        }
    }
}

@media only screen and (max-width: 1200px) {
    .collaborations-list {
        margin-left: 80px;
        margin-right: 80px;
    }
}

@media only screen and (max-width: 640px) {
    .collaborations-list {
        margin-left: 35px;
        margin-right: 35px;
        width: auto;
        align-self: auto;
    }
}

@media only screen and (max-width: 640px) {
    .collaborations-list {
        margin-left: 35px;
        margin-right: 35px;
    }
}
