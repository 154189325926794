@import 'src/styles/colors';

.header-add-channel {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: $bg-alternative;

    color: $basic-blue;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.005em;
    padding: 28px 82px;
    z-index: 1000;

    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;

    .exit {
        .button {
            width: 140px;
            height: 40px;
        }
    }
    .buttons {
        margin-top: 0 !important;
    }
    .button {
        padding: 0;
        text-transform: capitalize;
        img {
            margin-right: 12px;
        }
        .forward {
            transform: rotateY(180deg);
        }
    }
}

@media only screen and (max-width: 960px) {
    .header-add-channel {
        padding: 28px 32px;
    }
}

@media only screen and (max-width: 640px) {
    .header-add-channel {
        padding: 28px 10px;
        .buttons {
            .button {
                width: 110px;
            }
        }
        .exit {
            .button {
                width: 70px;
                height: 40px;
            }
        }
    }
}
