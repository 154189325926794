@import 'src/styles/colors';

.num-textarea {
    display: inline-flex;
    background: #FFFFFF;
    border: 2px solid #C9D5E3;
    border-radius: 6px;
    height: 170px;
    width: 100%;
    overflow-y: scroll;

    .num-textarea-container {
        display: flex;
        position: relative;
        width: 100%;
        height: fit-content;
    }

    .num-textarea-content {
        line-height: 28px;
        overflow: hidden;
        padding: 8px 12px;
        border: 0;
        outline: none;
        resize: none;
        width: 100%;
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 18px;
        color: #0C2C54;
    }

    .num-textarea-numbers {
        padding-top: 8px;
        display: flex;
        flex-direction: column;
        width: 40px;
        line-height: 28px;
        background: rgba(201, 213, 227, 0.5);
        align-items: center;
        color: #93AAC8;
        font-family: 'Roboto';
        font-weight: 500;
        font-size: 16px;
        padding-bottom: 18px;
    }

    .num-textarea-numbers--error {
        color: $error;
    }
}
