@import 'src/styles/colors';

.exchange {
    background: white;
    color: $dark-blue;
    padding-bottom: 80px;
    margin-top: 60px;

    .popup-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;

        .popup {
            background: white;
            position: fixed;
            max-width: 90%;
            padding: 10px 20px;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
            border: 1px solid rgba(147, 170, 200, 0.2);
            box-sizing: border-box;
            border-radius: 12px;
            font-size: 18px;
            line-height: 30px;
            display: flex;
            align-items: flex-end;
            flex-direction: column;
            letter-spacing: -0.015em;
            color: #3a4f68;
            .button {
                padding: 0;
            }
        }
    }

    .title {
        font-size: 40px;
        line-height: 60px;
        font-weight: 500;
    }

    .blocks {
        display: flex;
        margin-top: 40px;

        .block-title {
            font-size: 28px;
            line-height: 60px;
            font-weight: 500;
        }

        > div {
            padding: 0 80px;
        }

        > div:last-child {
            border-left: 1px solid #c4d2e3;
        }
    }
}

@media only screen and (max-width: 960px) {
    .exchange {
        .blocks {
            display: flex;
            flex-direction: column;

            .block-title {
                text-align: center;
            }

            > div:not(:first-child) {
                margin-top: 40px;
            }

            > div:last-child {
                border-left: none;
            }
        }
    }
}
