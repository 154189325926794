@import 'src/styles/colors';

.download-wrapper {
    padding-top: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $dark-blue;

    & > img {
        max-width: 395px;
    }

    & > h3 {
        font-weight: normal;
        font-size: 2rem;
        margin: 2.5rem 0 0 0;
    }

    & > p {
        max-width: 20rem;
        text-align: center;
        letter-spacing: -0.015em;
        font-size: 1.125rem;
        line-height: 1.875rem;
        margin: 0.5rem 0 1.5rem 0;

        & > span {
            font-weight: bold;
        }
    }

    .onePlatformDownload {
        min-width: 260px;

        > .button {
            margin: 14px auto;
            color: $light-blue;
        }
    }

    .download-button {
        width: 100%;
        margin: 0 auto;
        padding: 14px 10px;
    }

    .additional-links {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        color: $light-blue;

        & > span:last-child {
            display: none;
        }
    }

    .platform-label {
        white-space: nowrap;
    }

    .additional-button {
        padding: 0;
        margin: 10px auto 0;
        width: auto;
    }

    .additional-button:hover {
        text-decoration: underline;
    }

    & > .show-all-platforms {
        color: $basic-blue;
        margin-top: 1.5rem;
        cursor: pointer;
    }

    & > .all-platforms {
        display: flex;
        flex-wrap: wrap;
        width: 645px;

        & > .download-button {
            margin-bottom: 2rem;

            &:not(:nth-child(2n)) {
                margin-right: 1.5rem;
            }
        }
    }

    .platform-links {
        display: flex;
        flex-direction: column;
        width: 50%;
        padding: 0 12px;
        margin-bottom: 35px;
    }

    @media only screen and (max-width: 800px) {
        & > .all-platforms {
            width: 28.75rem;

            & > .download-button {
                width: 12.5rem;
            }
        }
    }

    @media only screen and (max-width: 500px) {
        & > .all-platforms {
            width: 100%;
            justify-content: center;

            & > .download-button {
                width: 75%;
                &:not(:nth-child(2n)) {
                    margin-right: 0;
                }
            }
        }

        .platform-links {
            width: 100%;
        }
    }
}
