@import 'src/styles/colors';

.channel-create-tags {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
    padding-bottom: 20px;

    &:not(:last-child) {
        border-bottom: 1px $rock-blue-lite solid;
    }

    .select-tags {
        display: flex;
        .select {
            width: 300px;
        }

        .tags {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 5px;
            margin-left: 12px;

            .tag {
                margin-right: 12px;
                color: white;
                padding: 8px 12px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 20px;
                font-weight: 500;
                font-size: 18px;
                line-height: 20px;
                background-color: $rock-blue;

                svg {
                    cursor: pointer;
                    margin-left: 5px;
                    path {
                        stroke: white;
                    }
                }
            }
        }
    }

    .title {
        font-weight: 500;
        font-size: 24px;
        line-height: 56px;
        color: $dark-blue;
        margin-bottom: 8px;
    }
}

.create-channel {
    &.preview {
        padding: 0 0;
        margin-top: 50px;

        .channel {
            .description {
                border-bottom: none;
            }
            .buttons {
                .button {
                    width: auto;
                    margin-left: 0;
                }
            }

            .publish {
                .button {
                    width: 265px;
                }
            }
        }
    }

    flex: 1 0 auto;
    margin-top: 130px;
    margin-bottom: 50px;
    padding: 0 100px;
    color: $dark-blue;

    .error {
        color: $error;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 26px;
        margin-top: 10px;
    }
    .check-channel {
        width: 100%;
        max-width: 600px;
        .button {
            height: 52px;
            margin-top: 24px;
        }

        .title {
            font-weight: 500;
            font-size: 40px;
            line-height: 60px;
            color: $dark-blue;
            margin-bottom: 40px;
        }
    }

    .description {
        border-bottom: 1px solid $rock-blue;
        padding: 24px 0;
        color: $rock-blue;
        font-size: 24px;
        line-height: 32px;
    }

    .text {
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        margin-top: 24px;
        white-space: pre-wrap;
    }

    .point {
        margin-top: 14px;
        font-size: 22px;
        line-height: 32px;
        display: flex;
        align-items: center;

        > span {
            background: $rock-blue;
            border-radius: 50%;
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            font-size: 14px;
            line-height: 18px;
            margin-right: 10px;
        }

        .tip {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover .tip-text {
                display: flex;
            }

            .tip-text {
                display: none;
                position: absolute;
                align-items: center;
                transform: translate(-30%, 40px);

                font-size: 14px;
                line-height: 18px;
                border-radius: 8px;
                padding: 11px;
                background: $bg-alternative;
                box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
                white-space: nowrap;
                z-index: 10;

                span {
                    font-weight: 500;
                    margin-left: 5px;
                }

                img {
                    width: 16px;
                    height: 16px;
                }
            }

            img {
                margin-left: 8px;
            }
        }
    }

    .buttons {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 20px;

        &.continue {
            .button {
                width: 300px;
            }
        }

        .button {
            width: 140px;
            height: 52px;
        }
    }
}

.channel-info {
    display: flex;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    padding-bottom: 35px;
    border-bottom: 1px solid $rock-blue;

    .info {
        display: flex;

        .rating {
            margin-left: 37px;
            margin-top: 8px;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            font-size: 18px;
            line-height: 30px;
            letter-spacing: -0.015em;
            color: $rock-blue;

            > div {
                display: flex;
                align-items: center;
                margin-right: 30px;
            }

            img {
                margin-left: 6px;
            }
        }
    }

    .link {
        color: $basic-blue;
    }
    .image-wrapper {
        margin-right: 24px;
        display: flex;
        justify-content: center;
    }
    .image-holder {
        border: 1px solid #c4d2e3;
        border-radius: 50%;
        width: 100px;
        height: 100px;
        background: #c4d2e3;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media only screen and (max-width: 960px) {
    .channel-create-tags {
        .select-tags {
            flex-direction: column;

            .tags {
                margin-top: 12px;
                margin-left: 0;

                .tag:first-child {
                    margin-left: 0;
                }
            }
        }
    }

    .create-channel {
        padding: 0 25px;

        .point {
            font-size: 18px;
            line-height: 30px;
            letter-spacing: -0.015em;
        }
    }
}

@media only screen and (max-width: 640px) {
    .create-channel {
        &.preview {
            .channel {
                .buttons {
                    .button {
                        width: 100%;
                    }
                }
                .publish {
                    .button {
                        width: 100% !important;
                    }
                }
            }
        }
    }
}
