.loader {
	width: 80px;
	height: 80px;
    transition: all 0.5s ease-in;
    animation-name: rotate;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
