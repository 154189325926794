@import 'src/styles/colors';

.header-staking {
    width: 100%;
    position: fixed;
    top: 0;
    background: $bg-alternative;

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 80px 0 80px;
    z-index: 1000;
    box-sizing: border-box;

    .logo-holder {
        display: flex;
        align-items: center;

        .logo {
            cursor: pointer;
            width: 90px;
            height: 60px;
        }

        .name {
            margin-left: 16px;
            font-weight: bold;
            font-size: 22px;
            line-height: 30px;
            letter-spacing: -0.015em;
        }
    }

    .button {
        width: 174px;
        font-weight: bold;

        img {
            margin-left: 9px;
        }
    }
}

@media only screen and (max-width: 960px) {
    .header-staking {
        .logo-holder {
            .name {
                display: none;
            }
        }
    }
}

@media only screen and (max-width: 640px) {
    .header-staking {
        padding: 10px 32px;
    }
}
