@import 'src/styles/colors';

.header-menu {
    padding: 27px 27px 0 32px;
    position: fixed;
    width: 100%;
    height: 100%;
    background: $bg-alternative;
    box-sizing: border-box;
    color: $dark-blue;
    overflow-y: scroll;

    .cancel {
        display: flex;
        justify-content: flex-end;

        svg {
            height: 25px;
            width: 25px;

            path {
                stroke: $dark-blue;
            }
        }
    }

    .list {
        .list-item {
            display: flex;
            align-items: center;
            font-size: 32px;
            line-height: 60px;
            margin-top: 12px;
            font-weight: bold;

            &:first-child {
                margin-top: 32px;
            }

            svg {
                width: 28px;
                height: 28px;

                path {
                    stroke: $dark-blue;
                    stroke-width: 2;
                }
            }

            &.open {
                color: $basic-blue;

                svg {
                    transform: rotate(180deg);

                    path {
                        stroke: $basic-blue;
                    }
                }
            }

            &.open + .sub-list {
                display: block;
            }
        }

        .sub-list {
            font-size: 24px;
            line-height: 36px;
            font-weight: bold;
            opacity: 0.7;
            display: none;

            .sub-list-item {
                margin-top: 16px;
                display: flex;
                align-items: center;

                &.inactive {
                    opacity: 0.7;
                }

                .soon-tag {
                    padding: 1px 8px;
                    line-height: 18px;
                }
            }
        }
    }
}
