@import 'src/styles/colors';

.input {
    width: 100%;
    position: relative;

    .badge {
        position: absolute;
        left: 11px;
        top: -12px;
        padding: 3px;
        background: white;
        color: $rock-blue;
    }
    > input:focus {
        outline: none !important;
        border: 2px solid $basic-blue;

        & + .badge {
            color: $basic-blue;
        }
    }

    &.invalid {
        > input {
            border: 2px solid $error !important;
        }
        > input:focus {
            outline: none !important;
            border: 2px solid $error;
        }

        .badge {
            color: $error !important;
        }
    }

    > input {
        outline: none !important;
        width: 100%;
        height: 60px;
        border: 2px solid $rock-blue;
        box-sizing: border-box;
        border-radius: 6px;
        font-size: 18px;
        line-height: 30px;
        letter-spacing: -0.015em;
        padding: 15px;
        color: $dark-blue;

        &::placeholder {
            color: $rock-blue;
        }
    }

    &.number {
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        > input[type='number'] {
            -moz-appearance: textfield;
        }
    }
}
