@import 'src/styles/colors';

.catalog-buttons-small {
    display: flex;
    padding: 6px;

    .catalog-button {
        color: $rock-blue;
        cursor: pointer;
        width: 123px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        transition: color, background-color, border-bottom-color;
        transition-duration: 0.5s;

        &:not(:last-child) {
            margin-right: 6px;
        }
        &.selected {
            color: $basic-blue;
            border-bottom: $basic-blue 2px solid;
        }
    }
}

@media only screen and (max-width: 640px) {
    .catalog-buttons-small {
        .catalog-button {
            width: fit-content;
            padding: 0 12px;
        }
    }
}
