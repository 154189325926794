@import 'src/styles/colors';

.checkout-buttons {
    display: flex;
    .period {
        &.active {
            border: 3px solid $success;

            .selected {
                position: absolute;
                right: 20px;
                top: 20px;
                visibility: visible;
                background: $success;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                width: 24px;
                height: 24px;
            }
        }

        &:not(:last-child) {
            margin-right: 16px;
        }

        .selected {
            visibility: hidden;
        }

        position: relative;
        cursor: pointer;
        border: 1px solid rgba(147, 170, 200, 0.3);

        box-sizing: border-box;
        border-radius: 12px;
        background: $bg-alternative;
        width: 200px;
        padding: 20px;
        color: $dark-blue;

        .time {
            font-weight: 500;
            font-size: 20px;
            line-height: 30px;
            display: flex;
            align-items: center;
            letter-spacing: -0.015em;
        }

        .price {
            font-weight: bold;
            font-size: 28px;
            line-height: 36px;
            display: flex;
            align-items: center;
        }
    }
}

.channel-checkout {
    color: $dark-blue;
    flex: 1 0 auto;

    .content {
        width: 460px;
        padding: 140px 100px;
    }

    .title {
        font-weight: 500;
        font-size: 40px;
        line-height: 60px;
    }

    .subtitle {
        margin-top: 32px;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
    }

    .lime-block {
        margin-top: 10px;
        background: rgba(0, 106, 237, 0.1);
        border-radius: 6px;
        padding: 17px;
        display: flex;
        justify-content: space-between;

        .lime-account-balance {
            font-weight: bold;
            font-size: 18px;
            line-height: 30px;
        }

        .button {
            width: 176px;
        }

        .error {
            color: $error;
            font-size: 14px;
            line-height: 18px;
        }
    }

    &.verified {
        .channel-info {
            margin-top: 50px;
        }

        .subtitle {
            margin-top: 5px;
        }

        button {
            width: 180px;
            height: 50px;
        }

        .fee-block {
            border-top: none !important;
        }
    }

    .fee-block {
        font-size: 22px;
        line-height: 32px;
        display: flex;
        justify-content: space-between;
        padding: 22px 0 16px;
        margin-top: 24px;
        border-top: 1px solid $rock-blue;

        .fee {
            display: flex;
            align-items: center;
            font-weight: 500;

            img {
                height: 20px;
                width: 20px;
                margin-right: 8px;
            }
        }
    }
}

@media only screen and (max-width: 640px) {
    .channel-checkout {
        color: $dark-blue;

        &.verified {
            .fee-block {
                flex-direction: column;

                > div {
                    display: flex;
                    justify-content: space-between;
                }
            }

            .button {
                margin-top: 16px;
                width: 100%;
            }
        }

        .content {
            box-sizing: border-box;
            width: 100%;
            padding: 100px 25px;

            .lime-block {
                flex-direction: column;

                .button {
                    margin-top: 10px;
                }
            }

            .fee-block {
                font-size: 18px;
                line-height: 30px;
            }
        }
    }
}
