* {
    box-sizing: border-box;
}
#root {
    overflow: hidden;
}

h1, h2, h3, h4, h5, h6, p {
    margin: 0;
}

.flex-container-wrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.flex-full-page {
    flex: 1 0 auto;
}

.flex-center {
    justify-content: center;
}

.page-bg {
    background-position: bottom;
    background-repeat: repeat-x;
    background-size: initial;
    min-height: 600px;
}

.tech-work {
    margin-top: 100px;
    margin-bottom: 100px;
}

@media only screen and (min-width: 1500px) {
    .page-bg {
        background-size: contain;
    }
}
