@import 'src/styles/colors';

.socials {
    background: $bg-alternative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $dark-blue;
    padding: 122px 0 0 0;

    .block {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
    }

    .ducks {
        margin-left: 100px;

        .animation-wrapper > div {
            display: none;
        }
    }
    .title {
        font-weight: bold;
        font-size: 38px;
        line-height: 60px;
    }

    .text {
        font-size: 22px;
        line-height: 36px;
        margin-top: 16px;
        white-space: pre-line;
        text-align: left;
    }

    .icons {
        margin-top: 28px;

        * {
            cursor: pointer;

            &:hover path {
                fill: $hover-primary;
            }
        }

        *:not(:first-child) {
            margin-left: 16px;
        }
    }

    .buttons {
        margin-top: 32px;

        .button {
            font-weight: bold;
            height: 48px;
            width: 170px;
        }
    }
}

@media only screen and (max-width: 1200px) {
    .socials {
        .ducks {
            margin-left: 30px;
        }
    }
}

@media only screen and (max-width: 1020px) {
    .socials {
        padding: 20px 0;
        flex-direction: column;

        .ducks {
            margin-left: 0;
            transform: scale(0.85);
        }

        .text {
            text-align: center;
        }

        .title {
            font-size: 40px;
            line-height: 48px;
        }
        .block {
            order: 2;
            align-items: center;

            &:last-child {
                order: 1;
            }
        }
    }
}

@media only screen and (max-width: 640px) {
    .socials {
        padding: 0;

        .title {
            padding: 0 10px 0 10px;
            text-align: center;
        }

        .text {
            font-size: 18px;
            line-height: 30px;
            /* or 167% */
            text-align: center;
            letter-spacing: -0.015em;
        }

        .ducks {
            transform: scale(0.65);
        }
    }
}
