@import 'src/styles/colors';

div.swap-progress-dialog-container {
    max-width: 500px;
    padding: 30px;

    h2 {
        font-size: 20px;
        margin-bottom: 32px;
    }
}

div.swap-progress-dialog-container--inprogress {
    padding-bottom: 40px;

    .swap-progress-dialog-loader {
        margin-bottom: 40px;
    }

    h2 {
        margin-top: 26px;
        margin-bottom: 10px;
    }

    .swap-progress-dialog-text {
        line-height: 24px;
        margin-bottom: 0px;
    }
}

div.swap-progress-dialog-container--short {
    padding-bottom: 10px;

    .swap-progress-dialog-loader {
        margin-bottom: 32px;
    }

    h2 {
        margin-bottom: 24px;
    }

    button {
        margin: 0 !important;
        max-width: 300px;
    }

    a {
        padding: 14px;
        text-decoration: none;
        font-size: 16px;
        color: $basic-blue;
        font-weight: 500;
    }
}

div.swap-progress-dialog-container--declined {
    padding-bottom: 30px;
}

.transfer-input-lime-icons {
    position: relative;

    .transfer-input-lime-icon-big {

    }

    .transfer-input-lime-icon-small {
        position: absolute;
        display: block;
        top: -5px;
        right: -7px;
        width: 18px;
        height: 18px;
    }
}

.swap-progress-dialog {
    display: flex;
    flex-direction: column;
    align-items: center;

    .swap-progress-dialog-text {
        max-width: 315px;
        text-align: center;
    }

    & > p {
        margin-bottom: 24px;
    }

    & > .swap-progress-dialog-coins {
        display: flex;
        align-items: center;
        height: 42px;
        width: 100%;
        justify-content: center;

        & > .swap-progress-dialog-coin {
            display: flex;
            align-items: center;
            width: 40%;
            height: 100%;

            &:first-child {
                justify-content: end;
            }

            &:last-child {
                justify-content: start;
            }

            & > div {
                &:first-child {
                    margin-right: .75rem;
                }
                &:last-child {
                    & > h5 {
                        font-weight: normal;
                        font-size: 16px;
                        margin-bottom: .125rem;
                    }
                    & > p {
                        font-size: .75rem;
                        color: $basic-grey;
                        white-space: nowrap;
                    }
                }
            }
        }

        & > img {
            margin: 0 1rem;
        }
    }

    & > button {
        margin-top: 1.5rem;
        background: $basic-blue;
        letter-spacing: -0.015em;
        padding: 1rem 4rem;
        border-radius: 6px;
        font-size: 17px;
        font-family: Roboto;
        font-weight: 500;
        cursor: pointer;
        border: none;
        outline: none;
        color: white;
        width: 100%;

        &:disabled {
            opacity: .5;
            cursor: not-allowed;
        }
    }
}

.swap {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.toast-wrapper {
    max-width: 540px;
    padding: 0 20px;
    width: 100%;
}

.service-wrapper {
    padding-bottom: 8px;
}

.swap-price-impact {
    margin-top: 15px;
}

.swap-form-meta--dialog {
    display: flex;
    flex-direction: column;
    background: gray;
    padding: 20px;
    background: #F6F6F6;
    border-radius: 12px;
    width: 100%;
    margin-top: 24px;

    & > div {
        width: 100%;
    }

    .swap-price-impact {
        margin-top: 0;
    }
}

.swap-form-meta {
    margin-bottom: 12px;

    .swap-form-meta-price-prefix {
        color: #676767;
        margin-left: 4px;
    }

    .swap-form-meta-icon {
        width: 14px;
        height: 14px;
        margin-right: 5px;
        margin-left: 1px;
    }

    .swap-form-meta-icon-bnb {
        path, circle {
            fill: #f8c811 !important;
        }
    }

    .swap-form-meta-icon-polygon {
        path, circle {
            fill: #8852df !important;
        }
    }

    & > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: .875rem;

        & > div:first-child {
            color: #676767;
        }

        &.swap-form-meta-price {
            strong {
                font-weight: 400;
                font-size: 16px;
            }

            & > div:last-child {
                display: flex;
                align-items: center;

                & > img {
                    margin: 0 .25rem;
                }
            }
        }
        &.swap-price-impact-warning > div:last-child {
            color: $gwei;
        }
        &.swap-price-impact-danger > div:last-child {
            color: $error;
        }
        &.swap-price-impact-ok > div:last-child {
            color: $success;
        }

        &:not(:last-child) {
            margin-bottom: 12px;
        }
    }
}

.swap-main {
    max-width: 540px;
    padding: 0 20px;
    width: 100%;

    & > header {
        margin: 1rem 0 2rem;
        & > h2 {
            font-size: 1.75rem;
            font-weight: 500;
            text-align: center;
            color: $dark-blue;
        }
    }

    & > form {
        & > .swap-form-img {
            margin: 6px 0 0;
            text-align: center;

            & > img {
                cursor: pointer;
            }
        }

        & > hr {
            border: none;
            height: 1px;
            background-color: $rockin-blue;
            margin: 20px 0 25px;
        }

        & > .swap-form-route {
            margin-bottom: 20px;

            & > h3 {
                font-size: 1.125rem;
                font-weight: 500;
            }
        }
    }

    & > button {
        border: none;
        font-size: 1rem;
        cursor: pointer;
        font-family: Roboto;
        font-weight: 500;
        border-radius: 6px;
        padding: 1rem 0;
        background-color: $basic-blue;
        color: white;
        width: 100%;

        &:disabled {
            opacity: .5;
            cursor: not-allowed;
        }

        &:last-child {
            margin-top: .5rem;
            padding: 21px;
        }

        .loading-dots {
            height: 19px;
        }
    }
}