@import 'src/styles/colors';

.info-block__pic-holder {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;

    > div > div {
        display: none;
    }
}

.info-block__container {
    background: $bg-alternative;
    padding: 36px 80px 36px 80px;
    display: flex;
    flex-flow: wrap;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 36px;
    letter-spacing: -0.015em;
    color: $dark-blue;

    .info-block__buttons {
        opacity: 0;
    }

    .animate {
        transform: translateX(0) !important;
        transition: ease-out 0.5s;

        .info-block__buttons {
            opacity: 1;
            transition-delay: 0.6s !important;
            transition: opacity ease-out 1s;
        }
    }
}

.info-block__text-block {
    width: calc(50% - 86px);

    text-align: left;
}

.info-block__text-block.left {
    transform: translateX(150%);
}

.info-block__text-block.right {
    transform: translateX(-150%);
}

.info-block__image-block {
    width: 50%;
    display: flex;
    background-repeat: no-repeat;
    background-size: 50%;
    position: relative;
}

.back-image {
    position: absolute;
    z-index: 0;
    top: 55%;
    transform: translate(-50%, -50%);
    width: 400px;
    height: 400px;
}

.image-left {
    left: 30%;
}

.image-right {
    left: 70%;
}

.info-block__container > div:last-child {
    margin-left: 86px;
}

.info-block__title {
    font-weight: 500;
    font-size: 38px;
    line-height: 60px;
    margin-top: 140px;
    display: flex;
    justify-content: flex-start;

    .hide {
        display: initial;
    }

    > div {
        position: relative;
    }

    .soon-tag {
        position: absolute;
        right: -50px;
        top: 0;
    }
}

.info-block__text {
    margin-top: 32px;

    div:not(:first-child) {
        margin-top: 10px;
    }
}

.info-block__buttons {
    margin-top: 27px;
    display: flex;
    position: relative;

    .soon-tag {
        position: absolute;
        margin-left: 180px;
        top: -10px;
    }

    * {
        cursor: pointer;
    }

    svg:hover {
        fill: $hover-secondary;
    }

    button {
        width: 188px;
    }
}

.info-block__buttons > *:not(:first-child) {
    margin-left: 16px;
}

@media only screen and (max-width: 1200px) {
    .info-block__title {
        .hide {
            display: none;
        }
    }
    .info-block__text {
        font-size: 18px;
        line-height: 30px;
        letter-spacing: -0.015em;
    }

    .info-block__buttons {
        button {
            border-width: 1px;
            font-size: 16px;
            line-height: 19px;
        }
    }
}

@media only screen and (max-width: 960px) {
    .back-image {
        top: 77%;
    }

    .info-block__title {
        margin-top: 60px;
        justify-content: center;
    }
    .info-block__buttons {
        justify-content: center;
    }
    .info-block__text-block {
        order: 1;
        width: 100%;
        text-align: center;
    }

    .info-block__container {
        padding: 36px 55px 36px 55px;
    }
    .info-block__image-block {
        order: 2;
        width: 100%;
    }

    .info-block__container > div:last-child {
        margin-left: 0;
    }
}

@media only screen and (max-width: 640px) {
    .info-block__container {
        padding: 36px 25px 0 25px;
    }

    .back-image {
        width: 180px;
        top: 50%;
    }

    .image-left {
        left: 27%;
    }

    .image-right {
        left: 75%;
    }

    .info-block__pic-holder {
        margin-top: -100px;
        transform: scale(0.75);
    }
    .info-block__buttons {
        > img {
            width: 150px;
            height: 44px;
        }
    }

    .info-block__title {
        margin-top: 0;

        .soon-tag {
            position: absolute;
            right: -35px;
            top: 0;
            transform: scale(0.75);
        }
    }
}
