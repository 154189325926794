@import 'src/styles/colors';

.catalog-card {
    &.new-channel {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        color: $rock-blue;
    }
    border: 1px solid $rock-blue;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 20px;
    font-weight: bold;
    width: 210px;
    cursor: pointer;
    position: relative;
    min-height: 169px;

    .status {
        text-transform: uppercase;
        position: absolute;
        right: 10px;
        top: -17px;
        border-radius: 16px;
        padding: 7px 10px;
        color: white;
    }

    .card-title {
        font-size: 22px;
        color: $dark-blue;
        margin-top: 19px;
        text-align: left;
        text-overflow: ellipsis;
        height: 80px;
        overflow: hidden;
        img {
            height: 22px;
            width: 22px;
            position: absolute;
            margin-top: 3px;
        }
    }

    .rating {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 18px;
        line-height: 30px;
        letter-spacing: -0.015em;
        color: $rock-blue;
        font-weight: normal;
        margin-top: 15px;

        > div {
            display: flex;
            align-items: center;
        }
        img {
            margin-left: 6px;
        }
    }

    .tag {
        color: white;
        background: $rock-blue;
        border-radius: 12px;
        padding: 4px 10px;
        width: fit-content;
        margin-top: 5px;
    }

    .image {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    .image-wrapper {
        display: flex;
        justify-content: center;
    }
    .image-holder {
        border: 1px solid #c4d2e3;
        border-radius: 50%;
        width: 110px;
        height: 110px;
        background: #c4d2e3;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}

@media only screen and (max-width: 640px) {
    .catalog-card {
        display: flex;
        width: 100%;

        .info {
            margin-left: 12px;
            width: 100%;
            text-align: left;

            .card-title {
                height: auto;
            }
        }
    }
}
