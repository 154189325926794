@import 'src/styles/colors';

.toast {
    background: $rock-blue;
    border-radius: 8px;
    display: flex;
    color: white;
    align-items: center;
    font-weight: bold;
    padding: 14px 20px;
    cursor: pointer;

    a:link {
        color: $basic-blue;
    }
    a:visited {
        color: $basic-blue;
    }
    a:hover {
        color: $basic-blue;
    }
    a:active {
        color: $basic-blue;
    }

    div:not(:only-child) {
        margin-left: 12px;
    }

    span {
        border-bottom: 1px solid white;
        cursor: pointer;
    }

    &--error {
        background: $error;
    }

    &--success {
        background: $success;
    }

    &--hidden {
        display: none;
    }

    &--sent {
        background: #4498ff;
    }
}
