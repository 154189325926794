@import 'src/styles/colors';

.landing-about {
    background-color: $basic-blue;
    padding: 140px 80px;
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    align-items: center;
    color: white;
    position: relative;
    overflow: hidden;

    .animation-wrapper {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 0;
        > div {
            display: none;
        }
    }

    .container {
        z-index: 20;
        position: relative;
        display: flex;

        justify-content: space-between;

        .description {
            width: calc(50% - 50px - 100px);
        }

        .integration {
            width: calc(50% - 50px + 100px);

            > .block {
                display: flex;
                justify-content: space-between;
                width: 100%;

                .brand {
                    display: flex;
                    align-items: center;
                    font-size: 22px;
                    line-height: 36px;
                    margin-bottom: 20px;
                    overflow: hidden;

                    > div {
                        margin-left: 16px;
                        white-space: nowrap;
                        transform: translateY(100%);
                        transition: 0.8s ease-in-out;
                    }
                }
            }
        }

        &.animated {
            .brand {
                > div {
                    transform: translateY(0) !important;
                }
            }

            .text {
                > div > div {
                    transform: translateY(0) !important;
                }
            }
        }
    }

    .title {
        font-weight: bold;
        font-size: 38px;
        line-height: 60px;

        margin-bottom: 32px;
    }

    .text {
        font-size: 22px;
        line-height: 36px;

        margin-top: 16px;

        > div:not(:first-child) {
            margin-top: 24px;
        }

        > div {
            overflow: hidden;

            > div {
                transition: 0.8s ease-in-out;
                transform: translateY(100%);
            }
        }
    }
}

@media only screen and (max-width: 1200px) {
    .landing-about {
        .container {
            display: inline-block;

            .integration {
                margin-top: 100px;
                width: 90%;
            }

            .description {
                width: 80%;
            }
        }
    }
}

@media only screen and (max-width: 640px) {
    .landing-about {
        .container {
            justify-content: center;

            .description {
                width: 100%;
            }

            .integration {
                width: 100%;

                > .block {
                    display: inline-block;
                    width: 100%;

                    .brand {
                        display: flex;
                        align-items: center;
                        font-size: 24px;
                        line-height: 36px;
                        margin-bottom: 20px;

                        > div {
                            white-space: nowrap;
                        }
                    }
                }
            }
        }

        .text {
            font-size: 18px;
            line-height: 30px;
            letter-spacing: -0.015em;
        }

        .animation-wrapper canvas {
            height: 730px !important;
            width: auto !important;
        }
    }
}
