@import 'src/styles/colors';

.airdrop-csv-example {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    line-height: 1.625em;

    & > p {
        & > strong {
            font-weight: 500;
        }
    }

    & > a {
        display: block;
        margin: 1rem 0 1.5rem;
        color: $basic-blue;
        font-weight: 500;
        text-decoration: none;
    }

    .csv-example-image {
        max-width: 554px;
        width: 100%;
    }
}

.airdrop {
    width: 100%;
    max-width: 848px;
    padding: 60px 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .airdrop-success {
        h5 {
            color: #93AAC8;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
        }

        a {
            color: #006AED;
            font-weight: 400;
            font-size: 18px;
            line-height: 30px;
            text-decoration: none;
            word-wrap: break-word;
        }
    }

    .airdrop-button {
        font-size: 1rem;
        outline: none;
        box-shadow: none;
        border: none;
        cursor: pointer;
        font-family: Roboto;
        background-color: $basic-blue;
        color: white;
        height: 50px;
        padding: 0;
        margin-top: 2.5rem;
        width: 100%;
        text-align: center;
        border-radius: 6px;

        &:disabled {
            cursor: not-allowed;
            opacity: 0.5;
        }
    }

    .airdrop-button-loading {
        cursor: default;
    }

    &-disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }

    .toast-wrapper,
    &-main {
        width: 100%;
    }
    .toast-wrapper {
        margin-bottom: 1rem;
        padding: 0;
        max-width: 100%;
    }

    &-main {
        background-color: white;
        border: 1px solid rgba(147, 170, 200, 0.2);
        box-shadow: 12px 12px 70px rgba(17, 51, 102, 0.1);
        border-radius: 10px;
        padding: 50px;

        & > header {
            display: flex;

            & > h2 {
                color: $dark-blue;
                font-weight: 500;
                font-size: 32px;
            }
        }

        & > .airdrop-wallet {
            margin: 1.5rem 0 2rem;
            padding-bottom: 2rem;
            border-bottom: 1px solid $rock-blue-lite;
            line-height: 1.5em;
            color: $head-text-dark;
        }

        .airdrop-form {
            margin-top: 45px;

            & > h3 {
                color: $head-text-dark;
                letter-spacing: -0.015em;
                font-size: 18px;
                margin-bottom: 20px;
            }

            .airdrop-form-header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: $rock-blue;
                margin-bottom: 12px;

                & > .airdrop-apply-all {
                    display: flex;
                    align-items: center;
                    color: $basic-blue;

                    & > label {
                        margin-left: 0.5rem;
                        cursor: pointer;
                    }
                }

                & > .airdrop-total-addresses {
                    max-width: 440px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }
            }

            & > .airdrop-tabs {
                display: flex;
                align-items: center;
                background: #e6f0fd;
                border-radius: 8px;
                width: fit-content;
                margin-bottom: 21px;

                & > button {
                    border: none;
                    font-size: 16px;
                    font-weight: 500;
                    font-family: Roboto;
                    border-radius: 6px;
                    padding: 6px;
                    width: 136px;
                    text-align: center;
                    color: $basic-blue;
                    background: none;
                    margin: 3px;
                    cursor: pointer;

                    &.airdrop-tabs-active {
                        background-color: $basic-blue;
                        color: white;
                    }
                }

                &.airdrop-disabled > button {
                    cursor: not-allowed;
                }
            }

            & > .airdrop-rows {
                & > section {
                    & > div {
                        display: flex;
                        align-items: center;

                        &:not(:last-child) {
                            margin-bottom: 1rem;
                        }

                        input {
                            font-size: 18px;
                            font-family: Roboto;
                            border: 1px solid $rockin-blue;
                            border-radius: 6px;
                            letter-spacing: -0.015em;
                            padding: 14px 16px;
                            color: #0C2C54;

                            &:disabled {
                                cursor: not-allowed;
                            }

                            &::placeholder {
                                color: $rock-blue;
                            }

                            &.airdrop-row-invalid {
                                border-color: $error;
                            }

                            &.airdrop-address-input {
                                max-width: 440px;
                                width: 100%;
                            }
                        }

                        & > div {
                            width: 100%;
                            flex: 1;
                            display: flex;
                            justify-content: stretch;
                            align-items: center;
                            padding-left: 15px;

                            & > input {
                                width: 100%;
                                min-width: 150px;
                            }
                        }

                        .airdrop-row-remove {
                            background: none;
                            border: none;
                            cursor: pointer;
                            width: 24px;
                            height: 24px;
                            display: block;
                            margin-left: 7px;
                            padding: 0;

                            &:disabled {
                                opacity: .4;
                                cursor: not-allowed;
                            }
                        }
                    }
                }
                & > footer {
                    margin-top: 18px;

                    & > button {
                        border: none;
                        background: transparent;
                        font-family: Roboto;
                        font-size: 18px;
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        color: $basic-blue;
                        padding: 0;
                        margin-left: 15px;

                        & > svg {
                            margin-right: 7px;
                        }

                        &:disabled {
                            opacity: .4;
                            cursor: not-allowed;
                        }
                    }
                }

                &.airdrop-disabled {
                    & > footer > button,
                    & > header > .airdrop-apply-all > label,
                    & > header > .airdrop-apply-all > button {
                        cursor: not-allowed;
                    }
                }
            }

            & > .airdrop-csv {

                & > section {
                    & > textarea {
                        resize: none;
                        border: 2px solid $rockin-blue;
                        border-radius: 6px;
                        width: 100%;
                        height: 150px;
                        overflow-y: scroll;
                        padding: 0.75rem;
                        font-family: Roboto;
                        font-size: 1rem;
                        line-height: 1.5em;
                        letter-spacing: -0.015em;
                    }
                }

                & > footer {
                    margin-top: 1rem;
                    & > .airdrop-csv-buttons {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        & > .airdrop-csv-upload {
                            & > input {
                                display: none;
                            }

                            .airdrop-file-btn {
                                cursor: pointer;
                                display: flex;
                                align-items: center;
                                border: 1px solid $basic-blue;
                                border-radius: 6px;
                                padding: 0.75rem;
                                font-weight: 500;
                                color: $basic-blue;
                                width: 167px;

                                .airdrop-file-btn-icon {
                                    margin-right: 0.75rem;
                                }

                                .airdrop-file-btn-close path {
                                    stroke: #93AAC8;
                                }

                                span {
                                    white-space: nowrap;
                                    width: 95px;
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                }
                            }
                        }
                        & > .airdrop-csv-example {
                            cursor: pointer;
                            color: $basic-blue;
                        }
                    }
                }
            }
        }

        .airdrop-footer {
            width: fit-content;
            margin: 10px auto 0;
            padding: 10px;
            text-align: center;
            font-weight: 500;
            color: $basic-blue;
            cursor: default;
        }

        .airdrop-footer-error {
            color: $error;
        }

        .airdrop-errors {
            font-size: 0.95rem;
            line-height: 1.75em;
            margin-top: 0.75rem;
            color: $error;
            letter-spacing: -0.015em;

            & > div {
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;

                span strong {
                    font-weight: 500;
                }
            }
        }
    }
}

.airdrop-approve-notion {
    font-family: 'Roboto';
    font-size: 16px;
    line-height: 24px;
    color: #3A4F68;

    strong {
        font-weight: 500;
    }
}