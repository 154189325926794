@import 'src/styles/colors';

$button-blue: rgb(0, 106, 237);
$button-disabled: rgba(0, 106, 237, 0.4);
$button-black: rgb(0, 0, 0);
$button-white: rgb(255, 255, 255);

.link {
    font-style: normal;
    font-family: Roboto;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    padding: 10px 20px;
    text-decoration: none;
    display: inline-block;
    text-align: center;
    color: $light-blue;

    &:visited {
        color: $light-blue;
    }

    &--blue-fill {
        color: $button-white;
        background: $button-blue;
        border-radius: 6px;

        &:hover:not(:disabled) {
            background: $hover-primary;
        }

        &:visited {
            color: $button-white;
        }
    }
}
