@import 'src/styles/colors';

.footer-bridge {
    display: flex;
    background: $bg-alternative;
    align-items: center;
    justify-content: center;
    padding: 24px 0;
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    color: $dark-blue;
    text-align: center;
    letter-spacing: -0.015em;
    border-top: rgba(147, 170, 200, 0.5) 1px solid;
}

.footer-wrapper {
    flex: 0 0 auto;
}

.swap-page-footer {
    padding: 1.5rem 0;
    border-top: 1px solid $rock-blue;
    text-align: center;
    letter-spacing: -0.015em;
    font-weight: 500;
    font-size: .875rem;
    background: $bg-alternative;

    & > a {
        color: $basic-blue;
        text-decoration: none;
    }
}

.footer {
    color: $dark-blue;
    padding: 80px 80px 20px 80px;
    background: $bg-alternative;

    display: flex;
    justify-content: space-between;

    .info {
        display: flex;
        flex-direction: column;
        width: 50%;
    }

    .copyright {
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.015em;

        white-space: pre-line;
    }

    .downloads {
        margin-top: 16px;
        width: 100%;

        .title {
            font-weight: bold;
            font-size: 15px;
            line-height: 24px;
            letter-spacing: -0.015em;
        }

        .buttons {
            display: flex;
            align-items: center;
            width: 100%;
            flex-wrap: wrap;
        }

        .buttons-block {
            display: flex;
        }

        .downloadButton {
            align-items: center;
            margin-top: 16px;
            margin-right: 12px;
            border: 1.5px solid #93AAC8;
            border-radius: 10px;

            &:hover {
                border-color: #006aed;

                svg {
                    path {
                        fill: #006aed;
                    }
                }
            }
        }

        svg {
            height: 44px;
            width: 154px;
            cursor: pointer;
            display: block;
            margin: auto;
        }
    }

    .links {
        width: 50%;
        padding-left: 120px;
        display: flex;
        justify-content: space-between;

        .block {
            font-size: 15px;
            line-height: 24px;
            letter-spacing: -0.015em;

            .title {
                font-weight: bold;
                font-size: 15px;
                line-height: 24px;
                letter-spacing: -0.015em;
            }

            .links-wrapper {
                margin-top: 12px;

                .inactive {
                    opacity: 0.4;
                }

                .soon-tag {
                    font-size: 12px;
                    padding: 1px 6px;
                    line-height: 16px;
                }

                > div {
                    display: flex;
                    justify-content: flex-start;
                    cursor: pointer;
                    align-items: center;
                }

                & > div:not(:last-child) {
                    margin-bottom: 0.25rem;
                }
            }
        }
    }
}

@media only screen and (max-width: 1580px) {
    .footer {
        flex-direction: column;

        .info {
            width: 100%;
            align-items: flex-start;
            justify-content: space-between;
            flex-direction: column;
            border-bottom: #93aac888 1px solid;
            padding-bottom: 24px;

            .copyright {
                margin-bottom: 16px;
            }

            .downloads {
                margin-top: 0;
                display: flex;
                align-items: flex-start;
                flex-direction: column;

                .title {
                    margin-right: 40px;
                }

                .buttons {
                    margin-top: 0;
                }
            }
        }

        .links {
            width: 100%;
            margin-top: 24px;
            margin-left: 0;
            justify-content: space-between;
            padding-left: 0;
        }
    }
}

@media only screen and (max-width: 960px) {
    .footer {
        .info {
            flex-direction: column;
            align-items: flex-start;

            .downloads {
                .title {
                    margin-right: 40px;
                }

                svg {
                    height: 40px;
                    width: 140px;
                }
            }
        }
    }
}

@media only screen and (max-width: 770px) {
    .footer {
        .downloads {
            .downloadButton {
                margin-top: 14px;
            }
        }
    }
}

@media only screen and (max-width: 640px) {
    .footer {
        padding: 30px;

        .info {
            flex-direction: column;
            align-items: flex-start;

            .downloads {
                flex-direction: column;
                align-items: flex-start;

                svg {
                    margin: 0;
                }

                .downloadButton {
                    margin-top: 0;
                }

                .buttons-block {
                    margin-top: 14px;
                }

                .buttons {
                    flex-direction: column;
                    align-items: self-start;
                }
            }
        }

        .links {
            flex-wrap: wrap;

            .block {
                width: 45%;
            }

            .third {
                flex-basis: 50%;
                margin-top: 32px;
            }

            .four {
                margin-top: 32px;
            }

            > :not(:first-child) {
                margin-left: 0;
            }
        }
    }
}
