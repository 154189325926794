@import 'src/styles/colors';

.header-fake {
    width: 100%;
    height: 100px;
}

.header {
    width: 100%;
    position: fixed;
    top: 0;
    background: white;
    height: 80px;
    box-sizing: border-box;
    padding: 0 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1000;
    transition: background-color 0.1s linear;

    .login-button.hover {
        .button {
            background: $hover-primary;
        }
    }

    .telegram-iframe {
        position: absolute;
        overflow: hidden;
        opacity: 0.001;
        z-index: 1000;
    }

    .account-logged {
        width: 48px;
        height: 48px;
        background: #006aed;
        border-radius: 50%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        .avatar {
            width: 48px;
            border-radius: 50%;
        }

        .dropdown-list {
            cursor: auto;
            position: fixed;
            padding-right: 0;
            padding-left: 0;
            padding-bottom: 0;
            top: 85px;
            right: 80px;
            display: block;
            min-width: 242px;

            .login-creds-wrapper {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 20px 15px 20px;
                img {
                    cursor: pointer;
                }
                .login-creds {
                    .name {
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 19px;
                        color: $dark-blue;
                    }

                    .link {
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 19px;
                        color: $basic-blue;
                        cursor: pointer;
                    }
                }
            }

            .item-wrapper {
                cursor: pointer;
                &:hover {
                    background: rgba(0, 106, 237, 0.12);
                    > .dropdown-item {
                        color: $dark-blue !important;
                    }
                }
                &:last-child {
                    padding-bottom: 0;
                    color: $error;

                    &:hover {
                        background: rgba(253, 104, 104, 0.1);
                        > .dropdown-item {
                            color: $error !important;
                        }
                    }

                    svg {
                        margin-right: 12px;
                        width: 15px;
                        height: 15px;

                        path {
                            fill: none;
                        }
                    }
                }
            }

            .dropdown-item {
                padding: 15px 0 15px;
                margin-left: 20px;
                border-bottom: 1px #93aac877 solid;
                justify-content: flex-start;

                &.out {
                    border-bottom: none;
                }

                svg {
                    margin-left: 0;
                    margin-right: 12px;
                    width: 15px;
                    height: 15px;

                    path {
                        fill: $dark-blue;
                    }
                }
            }
        }
    }

    .short {
        display: none;
    }

    &.transparent {
        background: rgba(255, 255, 255, 0);
        .logo {
            svg {
                path {
                    fill: white;
                }
            }
        }

        .buttons {
            .dropdown:hover svg path {
                stroke: #c5e0ff;
            }

            .dropdown:hover .button {
                color: #c5e0ff;
            }

            svg {
                path {
                    stroke: white;
                }
            }

            .button {
                color: white;

                &:hover {
                    color: #c5e0ff;
                }
            }
        }
    }

    .logo {
        top: 10px;
        height: 60px;
        cursor: pointer;
        visibility: hidden;
        opacity: 0;
        transition: 0.35s opacity, 0.35s visibility;

        svg {
            height: 60px;
            width: 90px;
        }

        &.visible {
            visibility: visible;
            opacity: 1;
        }
    }

    .burger {
        position: absolute;
        right: 32px;
        top: 27px;
        cursor: pointer;
        display: none;
    }

    .buttons {
        display: flex;
        justify-content: center;

        .button {
            color: $dark-blue;

            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 0.005em;
        }

        svg {
            path {
                stroke: $dark-blue;
            }
        }

        *:hover {
            color: $basic-blue;
        }

        > *:not(:first-child) {
            margin-left: 52px;
        }
    }

    .dropdown:hover .dropdown-list {
        display: block;
    }

    .dropdown:hover .button {
        color: $basic-blue;
    }

    .dropdown:hover svg path {
        stroke: $basic-blue;
    }

    .dropdown-list {
        color: $dark-blue !important;
        display: none;
        padding: 16px;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.12);
        border-radius: 12px;
        background: white;
        position: absolute;

        .dropdown-item {
            display: flex;
            align-items: center;

            &.inactive {
                opacity: 0.4;

                &:hover {
                    color: $dark-blue;
                }
            }

            &:not(:first-child) {
                margin-top: 12px;
            }

            &:hover {
                cursor: pointer;
                color: $basic-blue;
            }
        }
    }
}

.soon-tag {
    color: white !important;
    border-radius: 12px;
    padding: 2px 8px;
    font-weight: 500;
    background: linear-gradient(180deg, #006aed 0%, #0d99ff 100%);
    font-size: 14px;
    line-height: 18px;
    display: flex;
    text-align: center;
    letter-spacing: -0.005em;
    height: fit-content;
    margin-left: 8px;
}

@media only screen and (max-width: 1140px) {
    .header {
        .login-button {
            > button {
                .full {
                    display: none;
                }

                .short {
                    display: flex;
                    align-items: center;

                    svg {
                        width: 18px;
                        height: 18px;
                        margin-right: 12px;

                        path {
                            fill: $basic-blue;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1020px) {
    .header {
        &.transparent {
            .burger {
                svg {
                    path {
                        fill: white;
                    }
                }
            }

            .logo {
                svg {
                    path {
                        fill: white;
                    }
                }
            }
        }

        .buttons {
            display: none;
        }

        .logo {
            opacity: 1;
            visibility: visible;
        }

        .burger {
            display: block;
        }
    }
}

@media only screen and (max-width: 640px) {
    .header {
        padding: 0 80px 0 30px;
    }
}
