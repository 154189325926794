@import 'src/styles/colors';

.lime__container {
    background: $bg-alternative;

    .lime__buttons--main > *:not(:first-child) {
        margin-left: 16px;
    }

    .block {
        display: flex;
        position: relative;
        flex-wrap: wrap;
        gap: 32px;

        &:not(:first-child) {
            margin-left: 32px;
        }
    }

    .tooltip {
        display: none;
        position: absolute;
        bottom: -70px;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #f6fbff;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        padding: 6px 14px;
        font-size: 16px;
        line-height: 26px;
        color: $dark-blue;
        z-index: 1;
    }

    .inactive .market:hover .tooltip {
        display: none;
    }

    .market:hover .tooltip {
        display: block;
    }

    .inactive .market {
        position: static;
    }

    .market {
        display: flex;
        align-items: center;
        position: relative;
    }

    .certik {
        color: #3a4f68;
        font-weight: bold;
        font-size: 18px;
        line-height: 30px;
        letter-spacing: -0.015em;
        display: flex;
        width: fit-content;
        align-items: center;
        cursor: pointer;

        &:hover {
            color: $basic-blue;
            path {
                fill: $basic-blue;
            }
        }

        svg {
            margin-right: 8px;
        }
    }

    .background-div {
        position: absolute;
        width: 100%;
        height: 100%;
        background: no-repeat;
        background-position: top -100px right -100px;
        background-size: 600px;
        opacity: 0;
        max-height: 800px;
    }

    position: relative;
    z-index: 1;

    flex-flow: wrap;

    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 36px;
    letter-spacing: -0.015em;
    color: $dark-blue;

    .scan {
        opacity: 0;
    }

    > .content {
        padding: 36px 80px 100px 80px;
        transform: translateX(-150%);
    }

    .gate {
        display: flex;
        align-items: center;
    }

    .menu-external-wrapper {
        display: none;

        &.visible {
            display: block;
        }
    }

    .menu-wrapper {
        position: fixed;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
    }

    .trade-list {
        position: absolute;
        margin-top: 50px;
        margin-left: -120px;
        background: white;
        width: 220px;
        border: 1px solid rgba(147, 170, 200, 0.2);
        box-sizing: border-box;
        border-radius: 12px;
        z-index: 1;

        .title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 16px;

            font-weight: bold;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.015em;

            img {
                cursor: pointer;
            }
        }

        .item-wrapper {
            cursor: pointer;

            &:not(:last-child) > .item {
                border-bottom: 1px solid rgba(147, 170, 200, 0.5);
            }

            &:hover {
                background: rgba(0, 106, 237, 0.12);
            }

            &:last-child {
                border-radius: 0 0 12px 12px;
            }

            .item {
                margin-left: 20px;
                padding: 8px 0;
                display: flex;
                align-items: center;
                color: $dark-blue;
                font-size: 14px;
                line-height: 18px;

                img {
                    margin-right: 8px;
                    height: 24px;
                    width: 24px;

                    &:not(:first-child) {
                        margin-left: 4px;
                    }
                }
            }
        }
    }
}

.lime__title {
    font-weight: bold;
    font-size: 38px;
    line-height: 60px;
    margin-top: 140px;
    display: flex;
    align-items: baseline;

    .buttons {
        margin-left: 50px;
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        align-items: center;
    }

    .buttons > * {
        cursor: pointer;
    }

    img {
        height: 28px;
    }
}

.lime__text {
    margin-top: 24px;
    line-height: 36px;
    width: calc(50% - 86px);
}

.lime__buttons {
    margin-top: 27px;
    display: flex;

    button:first-child {
        width: 188px;
    }
}
.lime__buttons--main_legal-opinion:hover {
    background-color: $hover-primary;
    color: white;
    border-color: $hover-primary;
}

.lime__buttons img {
    cursor: pointer;
}

.lime__buttons.crypto {
    .market > img {
        height: 36px;
        width: 36px;
    }

    opacity: 0;
}

.lime__buttons.crypto.inactive .block > div:not(.gate.market) > img {
    opacity: 0.3;
}

.lime__buttons--main {
    opacity: 0;
}

.lime__container.lime-animate {
    .background-div {
        opacity: 1;
        transition: opacity ease-out 0.5s;
        transition-delay: 0.5s;
    }

    .content {
        transform: translateX(0);
        transition: ease-out 0.5s;

        .lime__buttons.crypto {
            opacity: 1;
            transition: opacity ease-out 1s;
            transition-delay: 1.2s;
        }

        .lime__buttons--main {
            opacity: 1;
            transition: opacity ease-out 0.5s;
            transition-delay: 0.9s;
        }

        .scan {
            opacity: 1;
            transition: opacity ease-out 0.5s;
            transition-delay: 0.5s;
        }
    }
}

@media only screen and (min-width: 1400px) {
    .lime__container {
        .background-div {
            height: 150%;
            background: no-repeat;
            background-position: top -100px right -100px;
        }
    }
}

@media only screen and (max-width: 1200px) {
    .lime__text {
        font-size: 18px;
        line-height: 30px;
    }
    .lime__title {
        display: inline-block;

        .buttons {
            margin-left: 0;
            margin-top: 40px;
        }
    }
}

@media only screen and (max-width: 960px) {
    .lime__text {
        width: 100%;
    }
}

@media only screen and (max-width: 640px) {
    .lime__container {
        .block {
            display: flex;

            &:not(:first-child) {
                margin-left: 0;
                margin-top: 32px;
            }
        }

        .background-div {
            background-position: right -200px center;
            background-size: contain;
        }

        .content {
            padding: 90px 35px 90px 35px;
        }

        .trade-list {
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
        }
    }
    .lime__title {
        white-space: pre-line;
        line-height: 48px;
        font-size: 38px;
        margin-top: 0;

        .buttons {
        }
    }
    .lime__text {
        width: 100%;
    }
    .lime__buttons--main {
        display: inline-block;

        button {
            border-width: 1px;
            font-size: 16px;
            line-height: 19px;
        }

        > * {
            margin-left: 0 !important;
            margin-top: 16px;
        }
    }

    .crypto {
        flex-direction: column;
    }

    .lime__buttons.crypto .block:not(:first-child) {
        margin-left: 0;
    }
}
