@import 'src/styles/colors';

.loader {
	width: 80px;
	height: 80px;
    transition: all 0.5s ease-in;
    animation-name: rotate;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

div.airdrop-progress-dialog-container {
    max-width: 500px;
    padding: 30px;

    h2 {
        font-size: 20px;
        margin-bottom: 32px;
    }
}

div.airdrop-progress-dialog-container--inprogress {
    padding-bottom: 40px;

    .airdrop-progress-dialog-loader {
        margin-bottom: 40px;
    }

    h2 {
        margin-top: 26px;
        margin-bottom: 10px;
    }

    .airdrop-progress-dialog-text {
        line-height: 24px;
        margin-bottom: 0px;
    }
}

div.airdrop-progress-dialog-container--short {
    padding-bottom: 10px;

    .airdrop-progress-dialog-loader {
        margin-bottom: 32px;
    }

    h2 {
        margin-bottom: 24px;
    }

    button {
        margin: 0 !important;
        max-width: 300px;
    }

    a {
        padding: 14px;
        text-decoration: none;
        font-size: 16px;
        color: $basic-blue;
        font-weight: 500;
    }
}

div.airdrop-progress-dialog-container--declined {
    padding-bottom: 30px;
}

.airdrop-progress-dialog {
    display: flex;
    flex-direction: column;
    align-items: center;

    .airdrop-progress-dialog-text {
        max-width: 315px;
        text-align: center;
    }

    & > p {
        margin-bottom: 24px;
    }

    & > .airdrop-progress-dialog-coins {
        display: flex;
        align-items: center;
        height: 42px;

        & > .airdrop-progress-dialog-coin {
            display: flex;
            align-items: center;
            width: 40%;
            height: 100%;

            & > div {
                &:first-child {
                    margin-right: .75rem;
                }
                &:last-child {
                    & > h5 {
                        font-weight: normal;
                        font-size: 16px;
                        margin-bottom: .125rem;
                    }
                    & > p {
                        font-size: .75rem;
                        color: $basic-grey;
                    }
                }
            }
        }

        & > img {
            margin: 0 1rem;
        }
    }

    & > button {
        margin-top: 1.5rem;
        background: $basic-blue;
        letter-spacing: -0.015em;
        padding: 1rem 4rem;
        border-radius: 6px;
        font-size: 17px;
        font-family: Roboto;
        font-weight: 500;
        cursor: pointer;
        border: none;
        outline: none;
        color: white;
        width: 100%;

        &:disabled {
            opacity: .5;
            cursor: not-allowed;
        }
    }
}

.airdrop-form-meta {
    margin-bottom: 12px;

    & > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: .875rem;

        & > div:first-child {
            color: #676767;
        }

        &:not(:last-child) {
            margin-bottom: 12px;
        }
    }
}

.airdrop-price-impact {
    margin-top: 15px;
}

.airdrop-form-meta--dialog {
    display: flex;
    flex-direction: column;
    background: gray;
    padding: 20px;
    background: #F6F6F6;
    border-radius: 12px;
    width: 100%;

    & > div {
        width: 100%;
    }

    .airdrop-price-impact {
        margin-top: 0;
    }
}