@import 'src/styles/colors';

.catalog {
    .loading-circle {
        margin-top: 60px;
    }
    margin-top: 60px;
    background: white;
    padding-bottom: 140px;

    .catalog-buttons-small {
        margin-top: 32px;
    }
    .title {
        color: $dark-blue;
        margin-bottom: 40px;
        font-size: 40px;
        font-weight: bold;
        line-height: 60px;
    }

    .wrapper {
        max-width: 1340px;
        width: calc(100% - 40px);
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .language-tabs-wrapper {
            width: 100%;
            display: flex;
            align-items: flex-end;

            .languages,
            .empty {
                flex: 1;
                width: 200px;
                > div {
                    width: 200px;
                }
            }
        }
    }

    .grid {
        margin-top: 30px;
        display: flex;
        flex-wrap: wrap;
        grid-column-gap: 15px;
        grid-row-gap: 20px;
        width: fit-content;
    }

    .categories-tags-wrapper {
        display: flex;
        width: 100%;
        margin-top: 20px;

        &.hidden {
            visibility: hidden;
        }

        &.open {
            flex-direction: column;

            .toggle {
                margin: 12px 12px 0 0;
            }
        }

        .toggle {
            width: 300px;
            max-width: fit-content;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: $basic-blue;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
    }

    .category-title {
        width: 100%;
        text-align: left;
        font-weight: 500;
        font-size: 36px;
        line-height: 60px;
        color: $dark-blue;
        margin-top: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .button {
            height: 40px;
        }
    }

    .categories-tags {
        display: flex;
        overflow: hidden;
        height: 50px;
        flex-wrap: wrap;

        &.open {
            height: fit-content;
            overflow: auto;
        }

        .tag {
            cursor: pointer;
            font-weight: 500;
            font-size: 16px;
            height: fit-content;
            line-height: 19px;
            background: $rock-blue;
            border-radius: 4px;
            color: white;
            margin: 12px 12px 0 0;
            padding: 4px 14px;
            transition: background-color;
            transition-duration: 0.3s;
            min-width: fit-content;

            &.selected {
                background: $basic-blue;
            }
        }
    }
}

@media only screen and (max-width: 1350px) {
    .catalog {
        .grid {
            grid-template-columns: repeat(4, 1fr);
        }
    }
}

@media only screen and (max-width: 960px) {
    .catalog {
        .wrapper {
            .language-tabs-wrapper {
                flex-direction: column;
                align-items: center;

                .languages {
                    margin-top: 30px;
                    order: 1;
                }
                .tabs {
                    order: 0;
                }
            }
        }
    }
}

@media only screen and (max-width: 640px) {
    .catalog {
        padding-bottom: 30px;

        .wrapper {
            width: calc(100% - 20px) !important;

            .language-tabs-wrapper {
                .languages {
                    width: 100%;
                    > div {
                        width: 100%;
                    }
                }
            }
        }

        .grid {
            width: 100% !important;
        }
    }
}
