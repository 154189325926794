@import 'src/styles/colors';

.my-catalog {
    .login-button.hover {
        .button {
            background: $hover-primary;
        }
    }

    .grid {
        width: 100%;
    }
    .login {
        padding: 0;
        display: flex;
        align-items: center;
        flex-direction: column;

        .block {
            margin-top: 40px;
        }

        .title {
            font-weight: 500;
            font-size: 36px;
            line-height: 60px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .button {
                height: fit-content;
            }
        }
    }

    .text {
        width: 500px;
        font-weight: 500;
        font-size: 20px;
        color: $dark-blue;
        letter-spacing: -0.015em;
        text-align: center;
        margin-top: 80px;
        margin-bottom: 32px;
        display: flex;
        flex-direction: column;
        white-space: pre-line;
        align-items: center;

        .button {
            width: 188px;
        }
    }

    .no-login {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .text {
            max-width: 440px;
        }

        .button {
            width: 188px;
        }
    }
}

@media only screen and (max-width: 640px) {
    .my-catalog {
        .text {
            width: 340px;
        }

        .block {
            width: calc(100% - 20px);
        }
    }
}
