$text-black: #000;
$bg-alternative: #f6fbffff;
$plug-grey: #e5e5e5ff;
$dark-blue: #0c2c54ff;
$head-text-dark: #3a4f68;
$basic-blue: #006aed;
$light-blue: #2988ff;
$rockin-blue: #C9D5E3;
$rock-blue: #93aac8;
$rock-blue-lite: #c4d2e3;
$error: #fd6868;
$error-hover: #e54747;
$success: #00ba77;
$success_hover: #06a46b;
$hover-primary: #005ac9;
$hover-secondary: rgba(0, 106, 237, 0.1);
$grey: #C4C4C4;
$basic-grey: #9A9A9A;
$basic-grey-4: #676767;
$telegram-dark-grey: #434F5D;
$gwei: #FF8102