@import 'src/styles/colors';

.landing-head {
    padding: 0 45px 0 45px;
    height: 100vh;
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    align-items: center;
    color: white;
    position: relative;
    background: $basic-blue;

    .lime-video {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;

        top: 0;
        width: 100%;
        z-index: 0;
        @media all and (orientation: portrait) {
            height: 100%;
        }
        @media all and (orientation: landscape) {
            width: 100%;
        }

        .white-block {
            position: absolute;
            width: 100%;
            height: 101%;
            background: linear-gradient(
                180deg,
                rgba(0, 106, 237, 0.6) 13.58%,
                #006aed 96%
            );
            z-index: 1;
        }
        video {
            @media all and (orientation: portrait) {
                height: 100%;
            }
            @media all and (orientation: landscape) {
                width: 100%;
            }
        }
    }

    .container {
        z-index: 2;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .title {
        font-weight: normal;
        font-size: 48px;
        line-height: 60px;
        text-align: center;

        margin-top: 24px;
    }

    .text {
        font-size: 32px;
        line-height: 40px;
        text-align: center;
        white-space: pre-line;

        margin-top: 16px;
    }

    .buttons {
        margin-top: 48px;

        * {
            cursor: pointer;
            width: 175px;
            height: 52px;
        }

        img:hover {
            transform: scale(1.05);
        }

        > *:not(:first-child) {
            margin-left: 32px;
        }
    }
}

@media only screen and (min-width: 1500px) {
    .landing-head {
        background-size: contain;
    }
}

@media only screen and (max-width: 1200px) {
    .landing-head {
        .buttons {
            * {
                cursor: pointer;
                width: 175px;
                height: 52px;
            }
        }

        .title {
            font-size: 40px;
            line-height: 60px;
        }

        .text {
            font-size: 24px;
            line-height: 36px;
        }
    }
}

@media only screen and (max-width: 960px) {
    .landing-head {
        .buttons {
            * {
                cursor: pointer;
                width: 162px;
                height: 48px;
            }
        }
    }
}

@media only screen and (max-width: 640px) {
    .landing-head {
        padding: 0 25px 0 25px;

        .logo {
            display: none;
        }

        .title {
            font-size: 36px;
            line-height: 48px;
            white-space: pre-line;
        }

        .text {
            font-size: 22px;
            line-height: 32px;
            text-align: center;
            margin-top: 16px;
        }

        .buttons {
            * {
                width: 145px;
                height: 44px;
            }

            > *:not(:first-child) {
                margin-left: 14px;
            }
        }
    }
}
