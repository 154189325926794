@import 'src/styles/colors';

.exchange-card {
    display: flex;
    width: 360px;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
    cursor: pointer;

    &:hover {
        .info-holder {
            svg {
                path {
                    fill: $hover-primary;
                }
            }
        }
    }

    .image-holder {
        min-width: 80px;
        min-height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        img {
            width: 51px;
            height: 51px;
        }
    }

    .info-holder {
        width: 100%;
        margin-left: 17px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }

    .title {
        font-size: 20px;
        line-height: 30px;
        color: $dark-blue;
        font-weight: 500;
    }

    .tags {
        display: flex;
        gap: 5px;
    }

    .tag {
        font-weight: 500;
        color: white;
        font-size: 14px;
        line-height: 18px;
        padding: 4px 8px;
        margin-top: 10px;
        border-radius: 12px;
        width: fit-content;
        letter-spacing: -0.01em;
    }
}

@media only screen and (max-width: 640px) {
    .exchange-card {
        width: 340px;
    }
}
