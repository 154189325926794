@import 'src/styles/colors';

.not-found {
    display: flex;
    flex-direction: column;
    margin-top: 30vh;
    align-items: center;

    .pic {
        width: 372px;
        height: 206px;
    }

    .text {
        width: 700px;
        text-align: center;
        color: $basic-blue;
        font-weight: bold;
        font-size: 32px;
        line-height: 44px;
    }

    flex: 1 0 auto;
}

@media only screen and (max-width: 640px) {
    .not-found {
        .pic {
            width: 270px;
            height: 150px;
        }

        .text {
            font-size: 24px;
            line-height: 36px;
            width: 340px;
        }
    }
}
