@import 'src/styles/colors';

.cookies-window {
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    position: fixed;
    bottom: 0;
    box-sizing: border-box;
    width: 100%;
    padding: 22px 32px;
    display: flex;
    justify-content: space-between;
    background: rgba(255, 255, 255, 0.91);
    z-index: 1000;
    color: $dark-blue;

    .title {
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
    }

    .text {
        font-size: 14px;
        line-height: 18px;
    }

    .buttons {
        display: flex;
        align-items: center;
        margin-left: 10px;
        .button {
            height: 44px;
            width: 140px;
            font-size: 16px;
            line-height: 19px;
            font-weight: bold;
            &:last-child {
                margin-left: 16px;
            }
        }
    }
}

@media only screen and (max-width: 640px) {
    .cookies-window {
        flex-direction: column;

        .buttons {
            margin-left: 0;
            margin-top: 10px;
        }
    }
}
