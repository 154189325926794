@import 'src/styles/colors';

.catalog-buttons {
    display: flex;
    background: rgba(0, 106, 237, 0.1);
    border-radius: 8px;
    padding: 6px;

    .catalog-button {
        color: $light-blue;
        cursor: pointer;
        width: 200px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        font-weight: bold;
        transition: color, background-color;
        transition-duration: 0.5s;

        > div {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        svg {
            margin-right: 10px;
            path {
                fill: $light-blue;
            }
        }

        &:not(:last-child) {
            margin-right: 6px;
        }

        &.selected {
            color: white;
            background: $light-blue;
            svg {
                path {
                    fill: white;
                }
            }
        }
    }
}

@media only screen and (max-width: 640px) {
    .catalog-buttons {
        .catalog-button {
            width: 102px;

            > div {
                > *:not(svg) {
                    display: none;
                }
                svg {
                    margin-right: 0;
                }
            }
        }
    }
}
