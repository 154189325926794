@import 'src/styles/colors';

.download-android-wrapper {
    padding: 10rem 0 5rem;

    & > .download-android-section {
        display: flex;
        justify-content: center;
        max-width: 1315px;
        margin: auto;

        & > div {
            width: 33.33%;
        }

        .download-android-block {
            margin: 0 110px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;

            & > h3 {
                font-weight: normal;
                font-size: 2rem;
                margin-bottom: 10px;
            }

            & > p {
                max-width: 20rem;
                letter-spacing: -0.015em;
                font-size: 1.125rem;
                line-height: 1.875rem;

                & > span {
                    font-weight: 500;
                }
            }

            & .download-android-note {
                color: $dark-blue;
                opacity: 0.7;
            }

            & .download-button {
                font-size: 18px;
                padding: 1rem 4.8rem;
                margin: 22px 0 28px 0;
            }
        }

        .download-android-img {
            width: 100%;
            max-width: 294px;
            margin-left: auto;
            margin-top: 16px;
            display: block;
        }

        .download-android-video {
            margin-top: 16px;
            margin-right: auto;
            position: relative;

            video {
                width: 230px;
                left: -18px;
                top: 0;
                position: absolute;
            }
        }

        .download-android-video-img {
            width: 100%;
            max-width: 196px;
            display: block;
        }
    }

    & > .download-android-prefer {
        text-align: center;
        margin-top: 9rem;

        & > p {
            margin: 0 0 0.75rem 0;
            color: $dark-blue;
            opacity: 0.7;
        }

        & > a {
            text-decoration: none;
            color: $basic-blue;
        }
    }

    @media only screen and (max-width: 1200px) {
        & > .download-android-section {
            flex-wrap: wrap;

            & > div {
                width: 100%;
            }

            .download-android-img {
                margin: auto;
            }

            .download-android-video-img {
                margin: auto;
            }

            .download-android-video video {
                left: 50%;
                transform: translateX(-50%);
            }

            .download-android-block {
                margin: 80px auto;
            }
        }
    }
}
