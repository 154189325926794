@import 'src/styles/colors';

.transfer-input-to {
    margin-top: -8px;
}

.transfer-input {
    & > header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: .5rem;

        & > h3 {
            font-size: 1.125rem;
            font-weight: 500;
            color: $dark-blue;
        }

        & > div {
            background-color: white;
            border-radius: 1rem;
            padding: 4px 8px;
            font-size: 14px;
            display: flex;
            align-items: center;

            & > img {
                margin-right: .25rem;
            }
        }
    }

    & > main {
        background-color: white;
        border-radius: 10px;
        box-shadow: 12px 12px 70px rgba(17, 51, 102, 0.1);
        display: flex;
        align-items: center;
        padding: 14px 24px;
        position: relative;

        & > div:first-child {
            width: 100%;
            border-right: 1px solid $grey;
            margin-right: 1rem;
            position: relative;

            & > input {
                width: 80%;
                border: none;
                outline: none;
                padding: 0;
                font-size: 1rem;

                &:disabled {
                    background: none;
                }
            }

            & > span {
                color: $basic-blue;
                position: absolute;
                top: 50%;
                right: 1rem;
                transform: translateY(-50%);
                cursor: pointer;
            }
        }
    }

    & > footer {
        margin-top: 9px;
        padding: 0 1.5rem;
        font-size: .875rem;
        color: $basic-grey;
    }
}

.transfer-input-select-value {
    margin-right: .5rem;
}

.transfer-input-select-item {
    display: flex;
    padding: .75rem 1rem;

    & > div:first-child {
        margin-right: .75rem;
    }
    & > div:last-child {
        & > h5 {
            font-weight: normal;
            font-size: 1rem;
            margin-bottom: .125rem;
        }
        & > p {
            color: $basic-grey;
            font-size: .75rem;
        }
    }
}