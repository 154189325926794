@import 'src/styles/colors';

.channel-state-screen {
    padding: 140px 100px;
    color: $dark-blue;
    max-width: 640px;

    .button {
        width: 188px;
        height: 52px;
    }
    .title {
        font-weight: 500;
        font-size: 40px;
        line-height: 60px;
    }

    .text {
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        padding: 32px 0;
    }
}

@media only screen and (max-width: 640px) {
    .channel-state-screen {
        padding: 140px 25px;
    }
}
