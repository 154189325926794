.empty-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.015em;
    color: #3a4f68;
    width: 400px;

    .text {
        margin-top: 30px;
    }
}
