@import 'src/styles/colors';

.popular {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .no-channels {
        .button {
            margin-top: 18px;
        }
    }
}

@media only screen and (max-width: 640px) {
    .popular {
        .empty {
            width: 100%;
        }
    }
}
