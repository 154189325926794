@import 'src/styles/colors';

.lime-metrics {
    padding: 0 80px;
    background: $bg-alternative;

    .title {
        color: $dark-blue;
        font-weight: 500;
        font-size: 28px;
        line-height: 60px;
    }

    .metric-block-external {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 40px;
    }

    .metric-block {
        display: flex;
    }

    .pie {
        width: 200px;
        height: 200px;

        margin-right: 30px;
        box-shadow: 0px 0px 40px 2px rgba(0, 106, 237, 0.2);
        border-radius: 50%;
    }

    .percents {
        .title-sector {
            margin-top: 12px;
            display: flex;
            align-items: center;
            font-size: 14px;
            line-height: 18px;
            color: #0c2c54;

            width: fit-content;

            &.supply {
                color: $basic-blue;
                padding-bottom: 12px;
                border-bottom: #c4d2e3 1px solid;
                opacity: 1;
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                span {
                    font-weight: bold;
                    margin-left: 20px;
                }

                .clickable {
                    cursor: pointer;
                }
                .block {
                    display: flex;
                    align-items: center;

                    .down {
                        margin-left: 7px;
                        transform: rotateX(180deg);
                        &.open {
                            transform: rotateX(0deg);
                        }
                    }
                    &.chains {
                        flex-direction: column;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 19px;
                        gap: 5px;
                        margin-top: 8px;
                        color: $dark-blue;
                        height: 0;
                        overflow: hidden;
                        transition: height 0.5s;

                        > div {
                            align-self: baseline;
                        }

                        .erc20 {
                            color: #8bbdfb;
                        }
                        .bep20 {
                            color: #f0b90b;
                        }
                        .polygon {
                            color: #8853df;
                        }

                        &.open {
                            height: 70px;
                        }
                    }
                }
            }
        }

        .percent {
            margin-top: 3px;
            font-size: 16px;
            line-height: 19px;
            color: $dark-blue;
            font-weight: bold;
        }

        .bullet {
            height: 12px;
            width: 12px;
            border-radius: 50%;
            margin-right: 8px;
        }
    }

    .prices {
        margin-left: 120px;
        width: 400px;

        > div {
            display: flex;
            justify-content: space-between;
            padding-bottom: 10px;
            margin-bottom: 14px;
            border-bottom: #c4d2e3 1px solid;
            font-size: 16px;
            line-height: 26px;
            color: #3a4f68;
            > span {
                font-size: 18px;
                line-height: 30px;
                letter-spacing: -0.015em;
                font-weight: bold;
            }
        }
    }
}

@media only screen and (max-width: 1200px) {
    .lime-metrics {
        .prices {
            width: 280px;
        }
    }
}

@media only screen and (max-width: 960px) {
    .lime-metrics {
        .prices {
            margin-left: 0;
            width: 450px;
            margin-top: 40px;
        }

        .metric-block-external {
            flex-direction: column;
        }
    }
}

@media only screen and (max-width: 640px) {
    .lime-metrics {
        padding: 0 36px;

        .metric-block {
            flex-direction: column;
            width: 100%;
        }

        .percent {
            margin-top: 24px;
        }

        .prices {
            width: 100%;
        }

        .pie {
            align-self: center;
            margin-right: 0;
            margin-bottom: 20px;
        }
    }
}
