@import 'src/styles/colors';

$button-blue: rgb(0, 106, 237);
$button-disabled: rgba(0, 106, 237, 0.4);
$button-black: rgb(0, 0, 0);
$button-white: rgb(255, 255, 255);

.button {
    &:disabled {
        opacity: 0.4;
        cursor: auto;
    }

    font-style: normal;
    font-family: Roboto;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    border: none;
    outline: none;
    background-color: rgba(0, 0, 0, 0);
    padding: 10px 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &--blue-border {
        font-size: 18px;
        line-height: 30px;
        letter-spacing: -0.015em;
        font-weight: 550;
        border: 2px solid $button-blue;
        border-radius: 6px;
        color: $button-blue;

        &:disabled {
            background: rgba(0, 0, 0, 0);
            opacity: 0.5;
            cursor: pointer;

            &:hover {
                background: rgba(0, 0, 0, 0);
            }
        }

        &:hover {
            background: $hover-secondary;
        }
    }

    &--blue {
        font-size: 18px;
        line-height: 30px;
        letter-spacing: -0.015em;
        font-weight: 550;
        color: $button-blue;
    }

    &--blue-fill {
        color: $button-white;
        background: $button-blue;
        border-radius: 6px;

        &:hover:not(:disabled) {
            background: $hover-primary;
        }
    }

    &--green-fill {
        color: $button-white;
        background: $success;
        border-radius: 6px;
        &:hover:not(:disabled) {
            background: $success_hover;
        }
    }

    &--red-border {
        color: $error;
        border: 2px solid $error;
        border-radius: 6px;

        &:hover:not(:disabled) {
            background: rgba(253, 104, 104, 0.1);
        }
    }

    &--red-fill {
        color: white;
        background: $error;
        border-radius: 6px;

        &:hover:not(:disabled) {
            background: $error-hover;
        }
    }

    &--light-blue-fill {
        color: $button-white;
        background: $light-blue;
        border-radius: 6px;

        &:hover {
            background: $hover-primary;
        }
    }

    &--black {
        color: $button-black;
    }

    &--black-border {
        border: 2px solid $button-black;
        border-radius: 6px;
        color: $button-black;
    }

    &--black-fill {
        color: $button-white;
        background: $button-black;
        border-radius: 6px;
    }

    &--full-width {
        width: 100%;
    }

    &--full-size {
        width: 100%;
        height: 100%;
    }

    &--bold {
        border-width: 2px;
    }
}

.app-button {
    cursor: pointer;

    &--blue {
        .logo {
            fill: $button-blue;
        }

        .text {
            fill: $button-blue;
        }

        .border {
            stroke: $button-blue;
        }
    }

    &--dark-blue {
        .logo {
            fill: $dark-blue;
        }

        .text {
            fill: $dark-blue;
        }

        .border {
            stroke: $dark-blue;
        }
    }
}

.loading-circle {
    height: 25px;
    width: 25px;

    border-radius: 50%;
    border: 4px solid rgba(0, 0, 0, 0);
    border-top: 4px solid white;
    animation: spin 2s linear infinite;

    &.blue {
        border-top: 4px solid $basic-blue;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
