@import 'src/styles/colors';

.collaborations {
    background: white;
    padding: 120px 0 30px 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;

    .title {
        font-weight: bold;
        font-size: 38px;
        line-height: 60px;
        width: 100%;
        text-align: center;
        color: $dark-blue;
    }

    .collaborations__list > button {
        margin: 100px auto 0;
        font-weight: 500;
    }
}

.collaborations__list > div {
    display: flex;
}

#container {
    height: 450px;
    position: relative;
    overflow: hidden;
    backface-visibility: hidden;
    width: 100vw;
}

.wrapper-top {
    position: absolute;
    top: 60px;
    left: 0;
    height: 200px;
    overflow: hidden;
    white-space: nowrap;
    animation: cards-move 190s linear infinite;
    will-change: transform;

    .photobanner {
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
        white-space: nowrap;
        animation: cards-move 190s linear infinite;
        will-change: transform;
        display: flex;
    }
}

.wrapper-bottom {
    margin-top: 150px;
    position: absolute;
    top: 60px;
    height: 200px;
    right: calc(100% - 100vw);
    overflow: hidden;
    white-space: nowrap;
    animation: cards-move-right 190s linear infinite;
    will-change: transform;

    .photobanner {
        position: absolute;
        top: 0;
        left: -100%;
        overflow: hidden;
        white-space: nowrap;
        animation: cards-move-right 190s linear infinite;
        will-change: transform;
        display: flex;
    }
}

.wrapper-third {
    margin-top: 300px;
}

.photobanner:hover {
    animation-play-state: paused;
}

.photobanner .item-wrapper {
    margin: 0 7px;
    width: 173px;
    cursor: pointer;
}

.colored {
    visibility: hidden;
}

.monochrome {
    position: absolute;
    z-index: 70;
    opacity: 0.3;

    &:hover {
        opacity: 0;

        + .colored {
            visibility: visible;
        }
    }
}

@keyframes cards-move {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-50%);
    }
}

@keyframes cards-move-right {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(50%);
    }
}
