@import 'src/styles/colors';

.claim {
    .field-wrapper {
        width: 400px;
    }
    .content {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        width: 300px;

        .button {
            margin-top: 32px;
            height: 60px;
        }

        .title {
            align-self: center;
        }

        .chain {
            margin-top: 32px;
            display: flex;

            .logo {
                width: 64px;
                height: 64px;
                box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
                border-radius: 12px;
                padding: 6px;

                svg {
                    width: 100%;
                    height: 100%;
                    g {
                        opacity: 1;
                    }
                }
            }

            .info {
                margin-left: 20px;

                .name {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 30px;
                    display: flex;
                    align-items: center;
                    letter-spacing: -0.015em;
                    color: $dark-blue;
                }
            }
        }

        .lime-amount {
            margin-top: 32px;
            width: 100%;

            .ssc-line {
                background-color: rgba(0, 106, 237, 0.17);
                height: 26px;
                margin-top: 10px;
            }

            .label {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 26px;
                display: flex;
                align-items: center;
                color: $basic-grey-4;
            }

            .amount {
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 30px;
                display: flex;
                align-items: center;
                letter-spacing: -0.015em;
                color: $basic-blue;

                margin-top: 10px;
            }
        }
    }
}

@media only screen and (max-width: 640px) {
    .claim {
        .field-wrapper {
            width: 360px;
        }
    }
}
